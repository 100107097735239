// Import createSlice from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

// Initial state for the slice
const initialState = {
  user: null, // Represents the logged in user
};

// Create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Action to update the state with the logged-in user
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    // Action to clear the user from state, effectively logging them out
    logoutUser: (state) => {
      state.user = null;
    },
  },
});

// Export the action creators
export const { loginUser, logoutUser } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;