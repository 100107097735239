import React, { useEffect, useState } from 'react';
import SignOutHeader from '../components/SignOutHeader';
import { Container, Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Pagination } from '@mui/material';

function TrackOrders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const clientID = 'rutmasdeveloper@gmail.com';

    const statusTranslations = {
        'processing': 'Procesando',
        'in transit': 'En Tránsito',
        'delivered': 'Entregado'
    };

    useEffect(() => {
        const fetchOrders = async () => {
            const encodedClientID = encodeURIComponent(clientID);
            const url = `${process.env.REACT_APP_API_URL}/orders/by-client/${encodedClientID}?page=${currentPage}&limit=15`;
            console.log("Attempting to fetch data from:", url);
            setIsLoading(true);
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }
                const data = await response.json();
                setOrders(data.orders);
                setTotalPages(data.totalPages);
            } catch (error) {
                setError('No se pudieron cargar los envíos');
                console.error("Fetch error:", error.message);
                setOrders([]);
                setTotalPages(0);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, [currentPage, clientID]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <SignOutHeader />
            <Container maxWidth="md" sx={{ mt: 0 }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
                    Seguimiento de Pedidos
                </Typography>
                {error && <Typography sx={{ color: 'grey', textAlign: 'center', mb: 2 }}>{error}</Typography>}
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : orders.length > 0 ? (
                    <>
                        {orders.map((order) => (
                            <Box key={order.serialNumber} sx={{ mb: 2 }}>
                                <TableContainer component={Paper} sx={{ border: '1px solid black' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Número de Serie</TableCell>
                                                <TableCell>{order.serialNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Nombre</TableCell>
                                                <TableCell>{order.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Teléfono</TableCell>
                                                <TableCell>{order.phoneNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Dirección</TableCell>
                                                <TableCell>{order.direction}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Ciudad</TableCell>
                                                <TableCell>{order.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Mensaje</TableCell>
                                                <TableCell>{order.message}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Tipo de entrega</TableCell>
                                                <TableCell>{order.deliveryType}</TableCell>
                                            </TableRow>
                                            {order.inTransitTime && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Tiempo en tránsito</TableCell>
                                                    <TableCell>{new Date(order.inTransitTime).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )}
                                            {order.deliveryTime && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Tiempo de entrega</TableCell>
                                                    <TableCell>{new Date(order.deliveryTime).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell component="th" scope="row">Estado</TableCell>
                                                <TableCell>{statusTranslations[order.status] || order.status}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                ) : (
                    !error && <Typography sx={{ textAlign: 'center', mt: 4 }}>No se encontraron órdenes para su cuenta.</Typography>
                )}
            </Container>
        </div>
    );
}

export default TrackOrders;






/*

import React, { useEffect, useState } from 'react';
import SignOutHeader from '../components/SignOutHeader';
import { Container, Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Pagination } from '@mui/material';

function TrackOrders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const clientID = 'rutmasdeveloper@gmail.com';

    const statusTranslations = {
        'processing': 'Procesando',
        'in transit': 'En Tránsito',
        'delivered': 'Entregado'
    };

    useEffect(() => {
        const fetchOrders = async () => {
            const encodedClientID = encodeURIComponent(clientID);
            const url = `${process.env.REACT_APP_API_URL}/orders/by-client/${encodedClientID}?page=${currentPage}&limit=15`;
            console.log("Attempting to fetch data from:", url);
            setIsLoading(true);
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }
                const data = await response.json();
                setOrders(data.orders);
                setTotalPages(data.totalPages);
            } catch (error) {
                setError(`No se pudieron cargar los envíos ${error.message}`);
                console.error("Fetch error:", error.message);
                setOrders([]);
                setTotalPages(0);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, [currentPage, clientID]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <SignOutHeader />
            <Container maxWidth="md" sx={{ mt: 0 }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
                    Seguimiento de Pedidos
                </Typography>
                {error && <Typography color="error" sx={{ textAlign: 'center', mb: 2 }}>{error}</Typography>}
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : orders.length > 0 ? (
                    <>
                        {orders.map((order) => (
                            <Box key={order.serialNumber} sx={{ mb: 2 }}>
                                <TableContainer component={Paper} sx={{ border: '1px solid black' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Número de Serie</TableCell>
                                                <TableCell>{order.serialNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Nombre</TableCell>
                                                <TableCell>{order.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Teléfono</TableCell>
                                                <TableCell>{order.phoneNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Dirección</TableCell>
                                                <TableCell>{order.direction}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Ciudad</TableCell>
                                                <TableCell>{order.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Mensaje</TableCell>
                                                <TableCell>{order.message}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Tipo de entrega</TableCell>
                                                <TableCell>{order.deliveryType}</TableCell>
                                            </TableRow>
                                            {order.inTransitTime && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Tiempo en tránsito</TableCell>
                                                    <TableCell>{new Date(order.inTransitTime).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )}
                                            {order.deliveryTime && (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Tiempo de entrega</TableCell>
                                                    <TableCell>{new Date(order.deliveryTime).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell component="th" scope="row">Estado</TableCell>
                                                <TableCell>{statusTranslations[order.status] || order.status}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                ) : (
                    !error && <Typography sx={{ textAlign: 'center', mt: 4 }}>No se encontraron órdenes para su cuenta.</Typography>
                )}
            </Container>
        </div>
    );
}

export default TrackOrders;







*/