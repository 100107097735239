import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Container, TextField, Typography, CircularProgress, Alert } from '@mui/material';

const Tracking = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const handleTrackOrder = async (event) => {
    event.preventDefault();
    if (!serialNumber) {
      setError('Por favor, ingrese un número de serie.');
      return;
    }
    setLoading(true);
    const upperCaseSerial = serialNumber.toUpperCase();
    const url = `${process.env.REACT_APP_API_URL}/orders/by-serial/${encodeURIComponent(upperCaseSerial)}`;
    console.log(`Requesting URL: ${url}`);
    try {
      const response = await axios.get(url);
      setLoading(false);
      if (response.data) {
        navigate(`/order-details/${upperCaseSerial}`);
      } else {
        setError('No se encontró ningún pedido con el número de serie proporcionado.');
      }
    } catch (error) {
      setLoading(false);
      setError('Error al cargar pedidos: ' + error.message);
      console.error('Error al buscar pedido:', error);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFFFFF', // Background color
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ color: '#000000' }} // Text color (Black)
      >
        Rastrea tu Envío
      </Typography>
      <Box
        component="form"
        onSubmit={handleTrackOrder}
        sx={{ width: '100%', mt: 1 }}
      >
        <TextField
          fullWidth
          label="Número de rastreo"
          variant="outlined"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value.toUpperCase())}
          disabled={loading}
          sx={{ backgroundColor: '#F5F5F5' }} // Secondary color
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            backgroundColor: '#1A237E', // Primary color
            color: '#FFFFFF', // Accent color (White text)
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Rastrear'
          )}
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default Tracking;
