// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDXR603MZaF30tsEkZuwd5JbihJXEYZKx4",
  authDomain: "rutmas-81981.firebaseapp.com",
  projectId: "rutmas-81981",
  storageBucket: "rutmas-81981.appspot.com",
  messagingSenderId: "934388173377",
  appId: "1:934388173377:web:ff02981e6434da9dd0d4c0",
  measurementId: "G-N8L6FDSDM0"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };


/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDXR603MZaF30tsEkZuwd5JbihJXEYZKx4",
  authDomain: "rutmas-81981.firebaseapp.com",
  projectId: "rutmas-81981",
  storageBucket: "rutmas-81981.appspot.com",
  messagingSenderId: "934388173377",
  appId: "1:934388173377:web:ff02981e6434da9dd0d4c0",
  measurementId: "G-N8L6FDSDM0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
//const analytics = getAnalytics(app);

export { app, auth};

*/