import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Using react-icons for the WhatsApp icon
import { Box } from '@mui/material';

const WhatsAppButton = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calculateBottom = (windowWidth, windowHeight) => {
        if (windowWidth > 1024) {
            return '1.25rem';
        } else if (windowWidth <= 1024 && windowHeight > 500) {
            return '0rem';
        } else {
            return '1rem';
        }
    };

    const calculateScale = (windowWidth, windowHeight) => {
        if (windowWidth <= 1024 && windowHeight > 500) {
            return 0.5;
        } else {
            return 1;
        }
    };

    const calculateRight = (windowWidth) => {
        if (windowWidth > 1024) {
            return '1.25rem';
        } else {
            return '0%';
        }
    };

    const bottom = calculateBottom(windowWidth, windowHeight);
    const scale = calculateScale(windowWidth, windowHeight);
    const right = calculateRight(windowWidth);

    const styles = {
        position: 'fixed',
        bottom: bottom,
        right: right,
        backgroundColor: '#25D366',
        color: 'white',
        padding: '0.625rem',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0.125rem 0.125rem 0.9375rem rgba(0, 0, 0, 0.2)',
        transform: `scale(${scale})`,
        zIndex: 1000,  // Ensure it's above other components
    };

    const phoneNumber = '573195803114'; // Replace with your WhatsApp phone number

    return (
        <Box
            component="a"
            href={`https://wa.me/${phoneNumber}`}
            sx={styles}
            target="_blank"
            rel="noopener noreferrer"
            title="Contact us on WhatsApp"
        >
            <FaWhatsapp size={60} color="white" />
        </Box>
    );
};

export default WhatsAppButton;
