import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import RegularHeader from '../components/RegularHeader';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/adminLogin`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
      });

      if (response.ok) {
        navigate('/admin-dashboard');
      } else if (response.status === 403) {
        setError('El usuario no tiene acceso de administrador');
      } else {
        setError('Error en el inicio de sesión de administrador');
      }
    } catch (error) {
      console.error("Error signing in: ", error.message);
      setError('Error al iniciar sesión');
    }
  };

  return (
    <div>
      <RegularHeader />
      <Container maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
          Inicio de sesión de administrador
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <TextField
            fullWidth
            label="Contraseña"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }} // Primary and Accent colors
          >
            Iniciar Sesión
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default AdminLogin;