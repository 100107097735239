import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignOutHeader from '../components/SignOutHeader';
import { Container, Box, Button, Typography } from '@mui/material';

function CheckPackages() {
    const navigate = useNavigate();

    useEffect(() => {
        function handleResize() {
            console.log("Resized");
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <SignOutHeader />
            <Container maxWidth="md" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
                    Seleccionar acción
                </Typography>
                <Box sx={{ width: '100%', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', padding: '10px 20px', fontSize: '16px' }}
                        onClick={() => navigate('/OrderForm')}
                    >
                        Nuevo envío
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', padding: '10px 20px', fontSize: '16px' }}
                        onClick={() => navigate('/trackOrders')}
                    >
                        Rastrear envío
                    </Button>
                </Box>
            </Container>
        </div>
    );
}

export default CheckPackages;






/*
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../CheckPackages.css'; // Ensure this path is correct
import RegularHeader from '../components/RegularHeader';

function CheckPackages() {
    const navigate = useNavigate();

    return (
        <div>
            <div> 
                <RegularHeader />
            </div>
            <div className="parent-container">
                <h2 className="title">Seleccionar usuario</h2> 
                <div className="button-container">
                    <button className="button" onClick={() => navigate('/OrderForm')}>Nuevo envío</button>
                    <button className="button" onClick={() => navigate('/trackOrders')}>Rastrear envío</button>
                </div>
            </div>
        </div>
    );
}

export default CheckPackages;


*/