import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';

const InfoBox = () => {
  useEffect(() => {
    const handleResize = () => {
      // This function is here if you need to perform any actions on resize in the future
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Box
        sx={{
          backgroundColor: '#F5F5F5', // Secondary color
          borderRadius: 2,
          p: 2,
          boxShadow: 1,
          textAlign: 'center',
          color: '#000000', // Text color
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Información de Envíos
        </Typography>
        <Typography variant="body1" paragraph>
          En Rutmas, nos especializamos en envíos rápidos y seguros desde Medellín, Sabaneta y Envigado a todo el Valle de Aburrá. Garantizamos la entrega de tus paquetes, ofreciendo tanto servicios regulares como nuestro exclusivo servicio Express, garantizado en menos de un día, único en el Valle de Aburrá.
        </Typography>
        <Typography variant="body1" paragraph>
          Con nosotros, tu envío no solo es rápido; también es completamente rastreable. Independientemente de si eliges el envío Regular o el Express, te ofrecemos una experiencia de envío superior con la tranquilidad de saber siempre dónde se encuentra tu paquete.
        </Typography>
        <Typography variant="body1" paragraph>
          Elige Rutmas para tus envíos. ¡Porque entendemos la importancia de cada paquete!
        </Typography>
      </Box>
    </Container>
  );
};

export default InfoBox;



/*
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Helper function to determine device type based on width and height
const getDeviceType = (width, height) => {
    if (width >= 2560) {
        return 'ultra-wide'; // Ultra-wide desktops
    } else if (width >= 1920) {
        return 'large-screen'; // Large desktops
    } else if (width >= 1440) {
        return 'desktop'; // Regular desktops
    } else if (width >= 1024) {
        return 'large-tablet'; // Large tablets like iPad Pro in portrait
    } else if (width >= 768) {
        return 'tablet'; // Standard tablets like iPad in portrait
    } else if (width >= 425) {
        return 'large-phone'; // Large phones
    } else if (width >= 375) {
        return 'medium-phone'; // Medium phones
    } else {
        return 'small-phone'; // Small phones
    }
};

// Dynamic top position calculation based on device type
const calculateTop = (deviceType) => {
    switch (deviceType) {
        case 'tablet':
        case 'large-tablet':
            return '40%'; // Higher for tablets
        case 'desktop':
        case 'large-screen':
        case 'ultra-wide':
            return '55%'; // Even higher for large screens
        default:
            return '17%'; // Default for phones
    }
};

// Dynamic scale calculation based on device type
const calculateScale = (deviceType) => {
    switch (deviceType) {
        case 'large-tablet':
        case 'desktop':
        case 'large-screen':
        case 'ultra-wide':
            return 0.8; // Less scaling for larger devices
        case 'tablet':
            return 0.73; // Slightly more scaling for standard tablets
        default:
            return 0.5; // More scaling for phones
    }
};

// Dynamic left position calculation based on device type
const calculateLeft = (deviceType) => {
    if (['tablet', 'large-tablet', 'desktop', 'large-screen', 'ultra-wide'].includes(deviceType)) {
        return '28%'; // Centered for larger devices
    } else {
        return '20%'; // Centered also for phones, adjust if needed
    }
};

const StyledInfoBox = styled.div`
  background-color: rgba(173, 216, 230, 0.2);
  border-radius: ${({ deviceType }) => ['tablet', 'large-tablet', 'desktop', 'large-screen', 'ultra-wide'].includes(deviceType) ? '15px' : '5px'};
  padding: ${({ deviceType }) => ['tablet', 'large-tablet', 'desktop', 'large-screen', 'ultra-wide'].includes(deviceType) ? '0.625rem 1.25rem' : '1rem'};
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  margin: 0.625rem;
  position: fixed;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  transform: translateX(-50%) scale(${({ scale }) => scale});
  color: #000000;
  max-width: ${({ deviceType }) => ['desktop', 'large-screen', 'ultra-wide'].includes(deviceType) ? '62%' : '90%'};
  font-size: ${({ fontSize }) => fontSize};
  z-index: 300;
`;

const Header = styled.h2`
  font-size: ${({ fontSize }) => parseFloat(fontSize) * 1.3}px;
  margin-bottom: 0.625rem;
`;

const Paragraph = styled.p`
  margin-bottom: 0.5rem;
  line-height: 1.4;
  font-size: ${({ fontSize }) => fontSize};
`;

const InfoBox = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const deviceType = getDeviceType(windowWidth, windowHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);

    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fontSize = windowWidth < 768 ? '0.8rem' : '0.9rem';
  const top = calculateTop(deviceType);
  const left = calculateLeft(deviceType);
  const scale = calculateScale(deviceType);

  return (
    <StyledInfoBox 
      fontSize={fontSize} 
      deviceType={deviceType} 
      top={top} 
      left={left} 
      scale={scale}
    >
      <Header fontSize={fontSize}>Información de Envíos</Header>
      <Paragraph fontSize={fontSize}>
        En Rutmas, nos especializamos en envíos rápidos y seguros desde Medellín, Sabaneta y Envigado a todo el Valle de Aburrá. Garantizamos la entrega de tus paquetes, ofreciendo tanto servicios regulares como nuestro exclusivo servicio Express, garantizado en menos de un día, único en el Valle de Aburrá.
      </Paragraph>
      <Paragraph fontSize={fontSize}>
        Con nosotros, tu envío no solo es rápido; también es completamente rastreable. Independientemente de si eliges el envío Regular o el Express, te ofrecemos una experiencia de envío superior con la tranquilidad de saber siempre dónde se encuentra tu paquete.
      </Paragraph>
      <Paragraph fontSize={fontSize}>
        Elige Rutmas para tus envíos. ¡Porque entendemos la importancia de cada paquete!
      </Paragraph>
    </StyledInfoBox>
  );
};

export default InfoBox;


*
/*
import React from 'react';

const InfoBox = () => {
  const infoBoxStyle = {
    backgroundColor: 'rgba(173, 216, 230, 0.2)',
    borderRadius: '15px',
    padding: '0.625rem 1.25rem',  // Converted from 10px 20px
    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',  // Converted shadow values
    margin: '0.625rem',  // Converted from 10px
    position: 'fixed',
    left: '0rem',  // Converted from 100px
    right: '-1.5625rem',  // Converted from -25px
    top: '26.125rem',  // Converted from 450px
    color: '#000000',
    maxWidth: '43.75rem',  // Converted from 700px
    fontSize: '0.9rem',  // Already in REMs
  };

  const headerStyle = {
    fontSize: '1.2rem',  // Already in REMs
    marginBottom: '0.625rem',  // Converted from 10px
  };

  const paragraphStyle = {
    marginBottom: '0.5rem',  // Converted from 8px
    lineHeight: '1.4',
  };
  return (
    <div style={infoBoxStyle}>
      <h2 style={headerStyle}>Información de Envíos</h2> 
      <p style={paragraphStyle}>
        En Rutmas, nos especializamos en envíos rápidos y seguros desde Medellín,
        Sabaneta y Envigado a todo el Valle de Aburrá. Garantizamos la entrega
        de tus paquetes, ofreciendo tanto servicios regulares como nuestro
        exclusivo servicio Express, garantizado en menos de un día, único en el
        Valle de Aburrá.
      </p>
      <p style={paragraphStyle}>
        Con nosotros, tu envío no solo es rápido; también es completamente rastreable.
        Independientemente de si eliges el envío Regular o el Express, te ofrecemos una
        experiencia de envío superior con la tranquilidad de saber siempre dónde se
        encuentra tu paquete.
      </p>
      <p style={paragraphStyle}>Elige Rutmas para tus envíos. ¡Porque entendemos la importancia de cada paquete!</p>
    </div>
  );
};

export default InfoBox;



*/