import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import RegularHeader from '../components/RegularHeader';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupCode, setSignupCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validación detallada de la contraseña
    const hasLower = /[a-z]/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isCorrectLength = password.length >= 12 && password.length <= 64;

    console.log("Resultados de la validación:", hasLower, hasUpper, hasDigit, hasSpecial, isCorrectLength);

    if (!hasLower || !hasUpper || !hasDigit || !hasSpecial || !isCorrectLength) {
      setError('La contraseña no cumple con los requisitos');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('¡Gracias por registrarte en Rutmas!');
      navigate('/'); // Redirige a la página de inicio después de un registro exitoso
    } catch (error) {
      console.error("Error durante el registro:", error);
      setError(error.message);
    }
  };

  return (
    <div>
      <RegularHeader />
      <Container maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
          Registrarse
        </Typography>
        <Box component="form" onSubmit={handleSignUp} sx={{ width: '100%', mt: 1 }}>
          <TextField
            fullWidth
            label="Correo electrónico"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <TextField
            fullWidth
            label="Contraseña"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <TextField
            fullWidth
            label="Código de registro"
            type="text"
            variant="outlined"
            value={signupCode}
            onChange={(e) => setSignupCode(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }} // Primary and Accent colors
          >
            Registrarse
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default SignUp;




/*
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import RegularHeader from '../components/RegularHeader';
import '../SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupCode, setSignupCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Detailed password validation
    const hasLower = /[a-z]/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isCorrectLength = password.length >= 12 && password.length <= 64;

    console.log("Validation Results:", hasLower, hasUpper, hasDigit, hasSpecial, isCorrectLength);

    if (!hasLower || !hasUpper || !hasDigit || !hasSpecial || !isCorrectLength) {
      setError('Password does not meet the requirements');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Thank you for signing up to Rutmas!');
      navigate('/'); // Redirects to Home after successful signup
    } catch (error) {
      console.error("Error during sign up:", error);
      setError(error.message);
    }
  };

  return (
    <div className="signup-form">
      <RegularHeader />
      <form onSubmit={handleSignUp}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Contraseña:</label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <label htmlFor="signupCode">Código de registro:</label>
        <input
          type="text"
          id="signupCode"
          name="signupCode"
          placeholder="Código de registro"
          value={signupCode}
          onChange={(e) => setSignupCode(e.target.value)}
        />

        {error && <p className="error-message">{error}</p>}

        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default SignUp;

*/

