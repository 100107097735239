import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignOutHeader from '../components/SignOutHeader';
import { auth } from '../firebaseConfig';
import { Container, Box, TextField, Button, Typography, TextareaAutosize } from '@mui/material';

function AdminAssignOrders() {
  const [deliveryPersonEmail, setDeliveryPersonEmail] = useState('');
  const [clearLogEmail, setClearLogEmail] = useState('');
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser || currentUser.email !== 'rutmasdeveloper@gmail.com') {
          navigate('/');
          console.log('User does not have access to AdminAssignOrders');
        }
      } catch (error) {
        console.error('Error checking admin access:', error);
      }
    };

    checkAdminAccess();
  }, [navigate]);

  const handleAssignOrders = async () => {
    const serialNumbers = selectedSerialNumbers.split(',').map(sn => sn.trim());
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/assignOrders`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ serialNumbers, deliveryPersonEmail }),
      });

      if (!response.ok) throw new Error('Failed to assign orders');
      alert('Orders assigned successfully');
      setSelectedSerialNumbers('');
      setDeliveryPersonEmail('');
    } catch (error) {
      alert(error.message);
      console.error('Error assigning orders:', error);
    }
  };

  const handleClearDeliveryLog = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clearDeliveryLog/${encodeURIComponent(clearLogEmail)}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to clear delivery log');
      const result = await response.json();
      if (result.result && result.result.nModified === 0) {
        alert('No logs to clear or already cleared.');
      } else {
        alert('Delivery log cleared successfully');
      }
      setClearLogEmail('');
    } catch (error) {
      alert(error.message);
      console.error('Error clearing delivery log:', error);
    }
  };

  const handleUpdateOrderStatuses = async (newStatus) => {
    const serialNumbers = selectedSerialNumbers.split(',').map(sn => sn.trim());
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/updateMultipleOrderStatuses`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ serialNumbers, status: newStatus }),
      });

      if (!response.ok) throw new Error(`Failed to update orders to ${newStatus}`);
      alert(`Orders updated to ${newStatus} successfully`);
      setSelectedSerialNumbers('');
    } catch (error) {
      alert(error.message);
      console.error('Error updating order statuses:', error);
    }
  };

  return (
    <div>
      <SignOutHeader />
      <Container maxWidth="md" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
          Asignar envío al Mensajero
        </Typography>
        <Box sx={{ width: '100%', mt: 4 }}>
          <Typography variant="h6" sx={{ color: '#1A237E' }}>Envíos</Typography>
          <TextareaAutosize
            minRows={4}
            value={selectedSerialNumbers}
            onChange={e => setSelectedSerialNumbers(e.target.value)}
            placeholder="Ingrese los números de serie de los pedidos separados"
            style={{ width: '100%', padding: '10px', borderRadius: '5px', borderColor: '#ccc' }}
          />
        </Box>
        <Box sx={{ width: '100%', mt: 4 }}>
          <Typography variant="h6" sx={{ color: '#1A237E' }}>Mensajero</Typography>
          <TextField
            fullWidth
            type="email"
            value={deliveryPersonEmail}
            onChange={e => setDeliveryPersonEmail(e.target.value)}
            placeholder="Correo del mensajero"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }}
            onClick={handleAssignOrders}
            disabled={!selectedSerialNumbers || !deliveryPersonEmail}
          >
            Asignar envíos
          </Button>
        </Box>
        <Box sx={{ width: '100%', mt: 4 }}>
          <Typography variant="h6" sx={{ color: '#1A237E' }}>Borrar registro de entrega de mensajero</Typography>
          <TextField
            fullWidth
            type="email"
            value={clearLogEmail}
            onChange={e => setClearLogEmail(e.target.value)}
            placeholder="Correo del mensajero"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }}
            onClick={handleClearDeliveryLog}
            disabled={!clearLogEmail}
          >
            Borrar registro de entrega
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }}
            onClick={() => handleUpdateOrderStatuses('in transit')}
          >
            Marcar como en tránsito
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }}
            onClick={() => handleUpdateOrderStatuses('delivered')}
          >
            Marcar como entregado
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default AdminAssignOrders;

