import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, Typography, CircularProgress, Table, TableBody, TableCell, TableRow, LinearProgress } from '@mui/material';
import RegularHeader from '../components/RegularHeader';

const translateStatus = (status) => {
  const statusTranslations = {
    'processing': 'Procesando',
    'in transit': 'En Tránsito',
    'delivered': 'Entregado'
  };
  return statusTranslations[status] || status; 
};

const OrderDetailsPage = () => {
  const { serialNumber } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const upperCaseSerial = serialNumber.toUpperCase();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/by-serial/${upperCaseSerial}`);
        if (response.data) {
          setOrderDetails(response.data);
        } else {
          setError('No se encontró ningún pedido con el número de serie proporcionado.');
        }
      } catch (error) {
        setError('Error al cargar los detalles del pedido.');
        console.error('Error al buscar detalles del pedido:', error);
      }
    };

    fetchOrderDetails();
  }, [serialNumber]);

  return (
    <>
      <RegularHeader />
      <Container maxWidth="md" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {error && <Typography color="error">{error}</Typography>}
        {orderDetails ? (
          <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E' }}>
              Detalles del Pedido
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Número de Serie:</TableCell>
                  <TableCell>{orderDetails.serialNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Nombre:</TableCell>
                  <TableCell>{orderDetails.name}</TableCell>
                </TableRow>
                {orderDetails.inTransitTime && (
                  <TableRow>
                    <TableCell component="th" scope="row">Tiempo en Tránsito:</TableCell>
                    <TableCell>{new Date(orderDetails.inTransitTime).toLocaleString()}</TableCell>
                  </TableRow>
                )}
                {orderDetails.deliveryTime && (
                  <TableRow>
                    <TableCell component="th" scope="row">Tiempo de Entrega:</TableCell>
                    <TableCell>{new Date(orderDetails.deliveryTime).toLocaleString()}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">Estado:</TableCell>
                  <TableCell>{translateStatus(orderDetails.status)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box sx={{ width: '100%', mt: 4 }}>
              <Typography variant="h6" component="div" sx={{ textAlign: 'center', color: '#1A237E' }}>
                Estado del Pedido
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <Typography variant="body2" sx={{ mr: 2, color: orderDetails.status === 'processing' ? '#1A237E' : '#ddd' }}>
                  Procesando
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={orderDetails.status === 'processing' ? 33 : orderDetails.status === 'in transit' ? 66 : 100}
                  sx={{
                    width: '50%',
                    height: '10px',
                    backgroundColor: '#ddd',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#1A237E'
                    }
                  }}
                />
                <Typography variant="body2" sx={{ ml: 2, color: orderDetails.status === 'delivered' ? '#1A237E' : '#ddd' }}>
                  Entregado
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 2, color: '#1A237E', textAlign: 'center' }}>
                {`Estado Actual: ${translateStatus(orderDetails.status)}`}
              </Typography>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </>
  );
};

export default OrderDetailsPage;