import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignOutHeader from '../components/SignOutHeader';
import { Container, Box, TextField, Button, Typography } from '@mui/material';

function AdminTracking() {
  const [serialNumber, setSerialNumber] = useState('');
  const navigate = useNavigate();

  const handleTrackOrder = async (event) => {
    event.preventDefault();
    if (!serialNumber) {
      alert('Por favor ingrese un número de serie.');
      return;
    }

    // Navigate to AdminTrackOrders with the entered serial number
    navigate(`/AdminTrackOrders/${serialNumber}`);
  };

  return (
    <div>
      <SignOutHeader />
      <Container maxWidth="sm" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#1A237E' }}>
          Buscar envío
        </Typography>
        <Box component="form" onSubmit={handleTrackOrder} sx={{ width: '100%', mt: 2 }}>
          <TextField
            fullWidth
            label="Por favor, ingrese un número de serie."
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', padding: '10px 20px', fontSize: '16px' }}>
            Buscar envío
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default AdminTracking;