// App.js

// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import Reset from './Pages/Reset';
import CheckPackages from './Pages/CheckPackages';
import OrderForm from './Pages/OrderForm';
import TrackOrders from './Pages/TrackOrders';
import AdminTrackOrders from './Pages/AdminTrackOrders';
import AdminTracking from './Pages/AdminTracking';
import CheckAdminDelivery from './Pages/CheckAdminDelivery';
import AdminLogin from './Pages/AdminLogin';
import DeliveryLogin from './Pages/DeliveryLogin';
import AdminDashboard from './Pages/AdminDashboard';
import DeliveryDashboard from './Pages/DeliveryDashboard';
import AdminAssignOrders from './Pages/AdminAssignOrders';
import DeliveryOrders from './Pages/DeliveryOrders';
import OrderDetailsPage from './Pages/OrderDetailsPage';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/check-packages" element={<CheckPackages />} />
          <Route path="/OrderForm" element={<OrderForm />} />
          <Route path="/TrackOrders" element={<TrackOrders />} />
          <Route path="/AdminTrackOrders/:serialNumber" element={<AdminTrackOrders />} />
          <Route path="/AdminTracking" element={<AdminTracking />} />
          <Route path="/check-admin-delivery" element={<CheckAdminDelivery />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/delivery-login" element={<DeliveryLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/delivery-dashboard" element={<DeliveryDashboard />} />
          <Route path="/AdminAssignOrders" element={<AdminAssignOrders />} />
          <Route path="/DeliveryOrders" element={<DeliveryOrders />} />
          <Route path="/order-details/:serialNumber" element={<OrderDetailsPage />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

/*
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import Reset from './Pages/Reset';
import CheckPackages from './Pages/CheckPackages';
import OrderForm from './Pages/OrderForm';
import TrackOrders from './Pages/TrackOrders';
import AdminTrackOrders from './Pages/AdminTrackOrders';
import AdminTracking from './Pages/AdminTracking';
import CheckAdminDelivery from './Pages/CheckAdminDelivery';
import AdminLogin from './Pages/AdminLogin';
import DeliveryLogin from './Pages/DeliveryLogin';
import AdminDashboard from './Pages/AdminDashboard';
import DeliveryDashboard from './Pages/DeliveryDashboard';
import AdminAssignOrders from './Pages/AdminAssignOrders';
import DeliveryOrders from './Pages/DeliveryOrders';
import OrderDetailsPage from './Pages/OrderDetailsPage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/check-packages" element={<CheckPackages />} />
          <Route path="/OrderForm" element={<OrderForm />} />
          <Route path="/TrackOrders" element={<TrackOrders />} />
          <Route path="/AdminTrackOrders/:serialNumber" element={<AdminTrackOrders />} />
          <Route path="/AdminTracking" element={<AdminTracking />} />
          <Route path="/check-admin-delivery" element={<CheckAdminDelivery />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/delivery-login" element={<DeliveryLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/delivery-dashboard" element={<DeliveryDashboard />} />
          <Route path="/AdminAssignOrders" element={<AdminAssignOrders />} />
          <Route path="/DeliveryOrders" element={<DeliveryOrders />} />
          <Route path="/order-details/:serialNumber" element={<OrderDetailsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

*/