import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import RegularHeader from '../components/RegularHeader';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const auth = getAuth();

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setError('');
    } catch (error) {
      console.error('Error al enviar el correo electrónico para restablecer la contraseña:', error);
      setSuccess(false);
      setError('Error al intentar restablecer la contraseña. Por favor, verifica que el correo electrónico esté registrado y vuelve a intentarlo.');
    }
  };

  return (
    <div>
      <RegularHeader />
      <Container maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
          Ingrese su Email
        </Typography>
        {success && <Alert severity="success" sx={{ mb: 2 }}>Correo electrónico para restablecer la contraseña enviado con éxito.</Alert>}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Box component="form" onSubmit={handleReset} sx={{ width: '100%', mt: 1 }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }} // Primary and Accent colors
          >
            Restablecer su contraseña
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Reset;



/*
import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import RegularHeader from '../components/RegularHeader';
import '../Reset.css';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const auth = getAuth();

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setError('');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setSuccess(false);
      setError(error.message);
    }
  };

  return (
    <div>
      <RegularHeader />
      <div className="reset-container">
        <div className="form-wrapper">
          <h2>Ingrese su Email</h2>
          {success && <p>Password reset email sent successfully.</p>}
          {error && <p>{error}</p>}
          <form onSubmit={handleReset}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="reset-btn">Restablecer su contraseña</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reset;

*/