//check if admin or mensajero to login 

import React from 'react';
import { useNavigate } from 'react-router-dom';
import RegularHeader from '../components/RegularHeader';
import { Container, Box, Button, Typography } from '@mui/material';

function CheckAdminDelivery() {
  const navigate = useNavigate();

  return (
    <div>
      <RegularHeader />
      <Container maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
          Seleccionar usuario
        </Typography>
        <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', width: '48%' }} // Primary and Accent colors
            onClick={() => navigate('/admin-login')}
          >
            Admin
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', width: '48%' }} // Primary and Accent colors
            onClick={() => navigate('/delivery-login')}
          >
            Mensajero
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default CheckAdminDelivery;

