import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { Container, Box, Button, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';

const AdminTrackOrders = () => {
  const { serialNumber } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState('');
  const [token, setToken] = useState('');
  const [clearInTransitTime, setClearInTransitTime] = useState(false);
  const [clearDeliveryTime, setClearDeliveryTime] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        getIdToken(user).then(setToken);
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (token && serialNumber) {
      const fetchOrder = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/by-serial/${serialNumber}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          if (response.data) {
            setOrder(response.data);
            setStatus(response.data.status);
          } else {
            console.error('No data received:', response);
            alert('Envío no encontrado');
          }
        } catch (error) {
          console.error('Failed to fetch order:', error);
          alert('Error al cargar los detalles del envío');
        }
      };
      fetchOrder();
    }
  }, [serialNumber, token]);

  const updateStatus = async () => {
    if (!order) {
      alert('No se cargó ningún envío');
      return;
    }
    try {
      const isClearingInTransitTime = status !== 'In Transit' && clearInTransitTime;
      const isClearingDeliveryTime = status !== 'Delivered' && clearDeliveryTime;

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/updateOrderStatus/${order._id}`, {
        status,
        clearInTransitTime: isClearingInTransitTime,
        clearDeliveryTime: isClearingDeliveryTime,
        setInTransitTime: status === 'In Transit',
        setDeliveryTime: status === 'Delivered'
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.data) {
        console.log('Status updated successfully:', response.data);
        setOrder({ ...order, status }); // Update the local order object
        setClearInTransitTime(false);
        setClearDeliveryTime(false);
      }
    } catch (error) {
      console.error('Failed to update order status:', error);
      alert(`Error al actualizar el estado del envío: ${error.message}`);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
        Administración de Envíos
      </Typography>
      
      {order ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
          <Typography variant="h6">Número de Serie del Envío: {order.serialNumber}</Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Estado</InputLabel>
            <Select value={status} onChange={e => setStatus(e.target.value)}>
              <MenuItem value="Processing">Procesando</MenuItem>
              <MenuItem value="In Transit">En Tránsito</MenuItem>
              <MenuItem value="Delivered">Entregado</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={clearInTransitTime}
                  onChange={() => setClearInTransitTime(!clearInTransitTime)}
                />
              }
              label="Limpiar tiempo en tránsito"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={clearDeliveryTime}
                  onChange={() => setClearDeliveryTime(!clearDeliveryTime)}
                />
              }
              label="Limpiar tiempo de entrega"
            />
          </Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', mt: 2 }}
            onClick={updateStatus}
          >
            Actualizar Estado
          </Button>
        </Box>
      ) : (
        <Typography sx={{ mt: 4, textAlign: 'center' }}>Cargando detalles del envío...</Typography>
      )}
    </Container>
  );
};

export default AdminTrackOrders;