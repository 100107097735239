import React from 'react';
import Header from '../components/Header';
import Contact from '../components/Contact';
import InfoBox from '../components/InfoBox';
import Tracking from './Tracking';
import WhatsAppButton from '../components/WhatsAppButton';
import { Box, Container } from '@mui/material';
import '../Home.css';

const Home = () => {
  return (
    <>
      <Header />
      <Box sx={{ mt: -10, pt: 2 }}> {/* Adjusted mt value to create space below header */}
        <Container maxWidth="lg">
          <Tracking />
          <InfoBox />
          <Contact />
          <WhatsAppButton />
        </Container>
      </Box>
    </>
  );
};

export default Home;



/*

Manifest.json Orginal

{
  "short_name": "Rutmas",
  "name": "Rutmas Servicios De Entrega",
  "icons": [
    {
      "src": "/favicon.ico?v=1.3",
      "sizes": "any",
      "type": "image/x-icon"
    },
    {
      "src": "/logo192.png",
      "type": "image/png",
      "sizes": "192x192"
    },
    {
      "src": "/logo512.png",
      "type": "image/png",
      "sizes": "512x512"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}


*/

/*
Lats last index.html 


<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8" />
    <link rel="icon" href="/favicon.ico?v=1.3" type="image/x-icon" />
    <!-- Set viewport width to device width and enable initial scale -->
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#000000" />
    <meta name="description" content="Envíos rápidos y seguros desde Medellín, Sabaneta y Envigado a todo el Valle de Aburrá. Servicios regulares y Express, todos rastreables." />
    <link rel="apple-touch-icon" href="/logo192.png?v=1.2" />
    <link rel="manifest" href="/manifest.json" />
    <title>Rutmas Servicios De Entrega</title>
</head>
<body>
    <noscript>Necesitas habilitar JavaScript para correr esta app.</noscript>
    <div id="root"></div>
</body>
</html>


*/










