import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const navItems = [{ text: 'Ingresa o Regístrate', to: '/login' }];

function Header(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: '#1A237E' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textAlign: 'left',
              color: '#FFFFFF',
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }
            }}
          >
            Rutmas
          </Typography>
          <Box>
            {navItems.map((item) => (
              <Button
                key={item.text}
                component={Link}
                to={item.to}
                sx={{
                  color: '#FFFFFF',
                  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
          {/* Your main content */}
        </Typography>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;







/*

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Rutmas from './Rutmas';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #eff0f4;
    margin: 0;
    padding: 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #d3d6db;
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 130px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.3s;

  @media (min-width: 344px) {
    right: 5px;
  }

  @media (min-width: 768px) {
    right: 5px;
  }

  @media (min-width: 1024px) {
    right: 20px;
  }

  @media (min-width: 1440px) {
    right: 20px;
  }

  @media (max-width: 850px) {
    top: 75%; // Lower the button significantly to avoid overlapping with the logo
    right: 20px; // Move it further to the right for visual balance
    transform: scale(0.75) translateY(-50%);
  }

  @media (max-width: 768px) {
    right: 15px; // Adjust for tablets and smaller devices
    transform: scale(0.8) translateY(-50%);
  }

  @media (max-width: 540px) {
    right: 5px; // Move closer to the edge on very small devices
    transform: scale(0.7) translateY(-50%);
  }
`;

const StyledLink = styled(Link)`
  background-color: #415f9d;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s;

  &:hover {
    background-color: #293b5f;
  }
`;

const Header = () => {
  return (
    <>
      <GlobalStyle />
      <StyledHeader>
        <LogoContainer>
          <Rutmas />
        </LogoContainer>
        <ButtonContainer>
          <StyledLink to="/login">Ingresa o Regístrate</StyledLink>
        </ButtonContainer>
      </StyledHeader>
    </>
  );
};

export default Header;

*/

/*
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Rutmas from './Rutmas';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #eff0f4;
    margin: 0;
    padding: 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #d3d6db;
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 130px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.3s;

  @media (min-width: 344px) {
    right: 10px;
  }

  @media (min-width: 768px) {
    right: 15px;
  }

  @media (min-width: 1024px) {
    right: 20px;
  }

  @media (min-width: 1440px) {
    right: 20px;
  }

  @media (max-width: 850px) {
    top: 75%; // Lower the button significantly to avoid overlapping with the logo
    right: 20px; // Move it further to the right for visual balance
    transform: scale(0.75) translateY(-50%);
  }

  @media (max-width: 768px) {
    right: 15px; // Adjust for tablets and smaller devices
    transform: scale(0.8) translateY(-50%);
  }

  @media (max-width: 480px) {
    right: 10px; // Closer to the edge on very small devices
    transform: scale(0.7) translateY(-50%);
  }
`;

const StyledLink = styled(Link)`
  background-color: #415f9d;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s;

  &:hover {
    background-color: #293b5f;
  }
`;

const Header = () => {
  return (
    <>
      <GlobalStyle />
      <StyledHeader>
        <LogoContainer>
          <Rutmas />
        </LogoContainer>
        <ButtonContainer>
          <StyledLink to="/login">Ingresa o Regístrate</StyledLink>
        </ButtonContainer>
      </StyledHeader>
    </>
  );
};

export default Header;

*/



