import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { loginUser } from '../store/UserSlice';
import RegularHeader from '../components/RegularHeader';
import { Container, Box, TextField, Button, Typography } from '@mui/material';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      dispatch(loginUser({
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
      }));
      navigate('/check-packages'); // Navigate to the next page
    } catch (error) {
      console.error("Error signing in: ", error.message);
    }
  };

  return (
    <div>
      <RegularHeader />
      <Container maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
          Acceder tu cuenta
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <TextField
            fullWidth
            label="Contraseña"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }} // Secondary color
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', mb: 2 }} // Primary and Accent colors
          >
            Iniciar Sesión
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button
              variant="contained"
              onClick={() => navigate('/check-admin-delivery')}
              sx={{ backgroundColor: '#F5F5F5', color: '#000000', width: '49%' }} // Secondary and Text colors
            >
              Admin/Mensajero
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/signup')}
              sx={{ backgroundColor: '#F5F5F5', color: '#000000', width: '49%' }} // Secondary and Text colors
            >
              Crear Cuenta
            </Button>
          </Box>
          <Button
            variant="text"
            onClick={() => navigate('/reset')}
            sx={{ color: '#1A237E', width: '100%' }} // Primary color for text
          >
            Olvidaste tu contraseña
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default Login;







/*
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { loginUser } from '../store/UserSlice';
import RegularHeader from '../components/RegularHeader';
import '../Login.css'; // Create a separate CSS file for styling

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch(loginUser({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      }));
      navigate('/check-packages');
    } catch (error) {
      console.error("Error signing in: ", error.message);
    }
  };
  return (
    <div>
      <RegularHeader />
      <div className="login-container">
        <div className="form-wrapper">
          <button
            onClick={() => navigate('/check-admin-delivery')}
            className="admin-delivery-btn"
          >
            Admin/Mensajero
          </button>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Contraseña:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="my-btn">Iniciar Sesión</button>
          </form>
          <div className="text-container">
            <div className="text-left">
              <button className="signup-btn" onClick={() => navigate('/signup')}>
              Crear Cuenta
              </button>
            </div>
            <div className="text-right">
              <button className="reset-btn" onClick={() => navigate('/reset')}>
              Olvidaste tu contraseña
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

*/

