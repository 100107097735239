import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    mensaje: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Enviando...');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);
      setStatus('Mensaje enviado con éxito');
      setFormData({ nombre: '', email: '', telefono: '', mensaje: '' });
    } catch (error) {
      setStatus('No se pudo enviar el mensaje');
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#000000' }}>
        Contacto
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Nombre"
          name="nombre"
          variant="outlined"
          value={formData.nombre}
          onChange={handleChange}
          required
          sx={{ backgroundColor: '#F5F5F5' }} // Secondary color
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
          required
          sx={{ backgroundColor: '#F5F5F5' }} // Secondary color
        />
        <TextField
          label="Teléfono"
          name="telefono"
          variant="outlined"
          value={formData.telefono}
          onChange={handleChange}
          required
          sx={{ backgroundColor: '#F5F5F5' }} // Secondary color
        />
        <TextField
          label="Mensaje"
          name="mensaje"
          variant="outlined"
          multiline
          rows={4}
          value={formData.mensaje}
          onChange={handleChange}
          required
          sx={{ backgroundColor: '#F5F5F5' }} // Secondary color
        />
        <Button type="submit" variant="contained" color="primary" sx={{ backgroundColor: '#1A237E', color: '#FFFFFF' }}>
          Enviar
        </Button>
        {status && <Typography variant="body2" color="textSecondary">{status}</Typography>}
      </Box>
    </Container>
  );
};

export default Contact;
