// Pages/NotFound.js
import React from 'react';

const NotFound = () => (
  <div>
    <h1>404 - Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
);

export default NotFound;
