import React from 'react';
import { useNavigate } from 'react-router-dom';
import SignOutHeader from '../components/SignOutHeader';
import { Container, Box, Button, Typography } from '@mui/material';

function AdminDashboard() {
  const navigate = useNavigate();

  const goToAdminTracking = () => {
    navigate('/AdminTracking');
  };

  const goToAdminAssignOrders = () => {
    navigate('/AdminAssignOrders');
  };

  return (
    <div>
      <SignOutHeader />
      <Container maxWidth="md" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
          Panel de administración
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', padding: '15px 30px', fontSize: '18px' }}
            onClick={goToAdminTracking}
          >
            Rastrear envíos
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', padding: '15px 30px', fontSize: '18px' }}
            onClick={goToAdminAssignOrders}
          >
            Asignar entrega administrador
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default AdminDashboard;