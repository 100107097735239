import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Ensure this is the correct path
import SignOutHeader from '../components/SignOutHeader';
import { 
    Container, 
    Box, 
    Typography, 
    CircularProgress, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableRow, 
    Paper, 
    Pagination, 
    Button 
} from '@mui/material';

function DeliveryOrders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const ordersPerPage = 15; // Adjust based on your pagination settings
    const navigate = useNavigate();

    const statusTranslations = {
        'processing': 'Procesando',
        'in transit': 'En Tránsito',
        'delivered': 'Entregado'
    };

    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        const currentUser = auth.currentUser;
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const email = currentUser.email.toLowerCase();
        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_API_URL}/deliveryOrders/${encodeURIComponent(email)}?page=${currentPage}&limit=${ordersPerPage}`;

        console.log("Fetching orders from URL:", url);

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            });
            if (!response.ok) {
                const responseBody = await response.text();
                setError(`HTTP error! Status: ${response.status}, Body: ${responseBody}`);
                throw new Error(`HTTP error! Status: ${response.status}, Body: ${responseBody}`);
            }
            const data = await response.json();
            setOrders(data.orders || []);
            setTotalPages(Math.ceil((data.totalOrders || 0) / ordersPerPage));
        } catch (error) {
            setError(`No se pudieron cargar los pedidos: ${error.toString()}`);
            console.error("Detailed Fetch error:", error);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, currentPage, ordersPerPage]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleStatusUpdate = async (orderId, statusText) => {
        const newStatus = statusText.toLowerCase();
        const currentUser = auth.currentUser;
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_API_URL}/updateOrderStatus/${orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` },
                body: JSON.stringify({ status: newStatus })
            });
            if (!response.ok) {
                const responseBody = await response.text();
                setError(`Error updating order status: ${responseBody}`);
                return;
            }
            fetchOrders(); // Refresh the orders after updating the status
        } catch (error) {
            setError(`Failed to update order status: ${error.toString()}`);
            console.error("Error updating order status:", error);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <SignOutHeader />
            <Container maxWidth="md" sx={{ mt: 0 }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E', textAlign: 'center' }}>
                    Órdenes de Entrega
                </Typography>
                {error && <Typography color="error" sx={{ textAlign: 'center', mb: 2 }}>{error}</Typography>}
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : orders.length > 0 ? (
                    <>
                        {orders.map((order) => (
                            <Box key={order._id} sx={{ mb: 2 }}>
                                <TableContainer component={Paper} sx={{ border: '1px solid black' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Número de Serie</TableCell>
                                                <TableCell>{order.serialNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Nombre</TableCell>
                                                <TableCell>{order.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Teléfono</TableCell>
                                                <TableCell>{order.phoneNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Dirección</TableCell>
                                                <TableCell>{order.direction}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Ciudad</TableCell>
                                                <TableCell>{order.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Mensaje</TableCell>
                                                <TableCell>{order.message}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Tipo de entrega</TableCell>
                                                <TableCell>{order.deliveryType}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Estado</TableCell>
                                                <TableCell>{statusTranslations[order.status] || order.status}</TableCell>
                                            </TableRow>
                                            {order.status === 'processing' && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Button variant="contained" color="primary" onClick={() => handleStatusUpdate(order._id, 'in transit')}>
                                                            Marcar como En Tránsito
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {order.status === 'in transit' && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Button variant="contained" color="secondary" onClick={() => handleStatusUpdate(order._id, 'delivered')}>
                                                            Marcar como Entregado
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                ) : (
                    !error && <Typography sx={{ textAlign: 'center', mt: 4 }}>No se encontraron órdenes para su cuenta.</Typography>
                )}
            </Container>
        </div>
    );
}

export default DeliveryOrders;






/*
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Ensure this is the correct path
import SignOutHeader from '../components/SignOutHeader';
import '../DeliveryOrders.css';

function DeliveryOrders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const ordersPerPage = 15; // Adjust based on your pagination settings
    const navigate = useNavigate();

    const statusTranslations = {
        'processing': 'Procesando',
        'in transit': 'En Tránsito',
        'delivered': 'Entregado'
    };

    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        const currentUser = auth.currentUser;
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const email = currentUser.email.toLowerCase();
        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_API_URL}/deliveryOrders/${encodeURIComponent(email)}?page=${currentPage}&limit=${ordersPerPage}`;

        console.log("Fetching orders from URL:", url);

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            });
            if (!response.ok) {
                const responseBody = await response.text();
                setError(`HTTP error! Status: ${response.status}, Body: ${responseBody}`);
                throw new Error(`HTTP error! Status: ${response.status}, Body: ${responseBody}`);
            }
            const data = await response.json();
            setOrders(data.orders || []);
            setTotalPages(Math.ceil((data.totalOrders || 0) / ordersPerPage));
        } catch (error) {
            setError(`No se pudieron cargar los pedidos: ${error.toString()}`);
            console.error("Detailed Fetch error:", error);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, currentPage, ordersPerPage]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleStatusUpdate = async (orderId, statusText) => {
        const newStatus = statusText.toLowerCase();
        const currentUser = auth.currentUser;
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_API_URL}/updateOrderStatus/${orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` },
                body: JSON.stringify({ status: newStatus })
            });
            if (!response.ok) {
                const responseBody = await response.text();
                setError(`Error updating order status: ${responseBody}`);
                return;
            }
            fetchOrders(); // Refresh the orders after updating the status
        } catch (error) {
            setError(`Failed to update order status: ${error.toString()}`);
            console.error("Error updating order status:", error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)} className="page-item">
                        &laquo;
                    </button>
                )}
                {pageNumbers.map(number => (
                    <button key={number} className={`page-item ${currentPage === number ? 'active' : ''}`} onClick={() => handlePageChange(number)}>
                        {number}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(currentPage + 1)} className="page-item">
                        &raquo;
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="delivery-orders-page">
            <SignOutHeader />
            <div className="delivery-orders-container">
                <h1>Órdenes de Entrega</h1>
                {isLoading ? (
                    <p>Cargando pedidos...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : orders.length > 0 ? (
                    <>
                        {orders.map((order) => (
                            <div key={order._id} className="order-container">
                                <table className="order-details">
                                    <tbody>
                                        <tr>
                                            <th>Número de Serie</th>
                                            <td>{order.serialNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Nombre</th>
                                            <td>{order.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Teléfono</th>
                                            <td>{order.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Dirección</th>
                                            <td>{order.direction}</td>
                                        </tr>
                                        <tr>
                                            <th>Ciudad</th>
                                            <td>{order.city}</td>
                                        </tr>
                                        <tr>
                                            <th>Mensaje</th>
                                            <td>{order.message}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipo de entrega</th>
                                            <td>{order.deliveryType}</td>
                                        </tr>
                                        <tr>
                                            <th>Estado</th>
                                            <td>{statusTranslations[order.status] || order.status}</td>
                                        </tr>
                                        {order.status === 'processing' && (
                                            <button onClick={() => handleStatusUpdate(order._id, 'in transit')}>
                                                Marcar como En Tránsito
                                            </button>
                                        )}
                                        {order.status === 'in transit' && (
                                            <button onClick={() => handleStatusUpdate(order._id, 'delivered')}>
                                                Marcar como Entregado
                                            </button>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {renderPagination()}
                    </>
                ) : (
                    <p>No se encontraron órdenes para su cuenta.</p>
                )}
            </div>
        </div>
    );
}

export default DeliveryOrders;
*/