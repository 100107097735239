import React, { useState, useEffect } from 'react';
import SignOutHeader from '../components/SignOutHeader';
import { useSelector } from 'react-redux';
import { Container, Box, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, CircularProgress, Alert } from '@mui/material';

const OrderForm = () => {
  const clientEmail = useSelector(state => state.user.user?.email);

  const initialFormData = {
    clientID: clientEmail || '',
    name: '',
    phoneNumber: '',
    direction: '',
    city: '',
    message: '',
    deliveryType: 'Regular'
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientEmail) {
      setFormData(currentFormData => ({ ...currentFormData, clientID: clientEmail }));
      setLoading(false);
    }
  }, [clientEmail]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (submitSuccess) {
      setSubmitSuccess(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.length < 3) errors.name = 'El nombre debe tener al menos 3 caracteres';
    if (formData.name.length > 100) errors.name = 'El nombre no puede tener más de 100 caracteres';
    if (formData.phoneNumber.length < 7) errors.phoneNumber = 'El número de teléfono debe tener al menos 7 caracteres';
    if (formData.phoneNumber.length > 20) errors.phoneNumber = 'El número de teléfono no puede tener más de 20 caracteres';
    if (formData.direction.length < 10) errors.direction = 'La dirección debe tener al menos 10 caracteres';
    if (formData.direction.length > 200) errors.direction = 'La dirección no puede tener más de 200 caracteres';
    if (formData.city.length < 2) errors.city = 'La ciudad debe tener al menos 2 caracteres';
    if (formData.city.length > 50) errors.city = 'La ciudad no puede tener más de 50 caracteres';
    if (formData.message.length < 5) errors.message = 'El mensaje debe tener al menos 5 caracteres';
    if (formData.message.length > 250) errors.message = 'El mensaje no puede tener más de 250 caracteres';

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors).join('\n'));
      return;
    }

    console.log("Enviando estos datos:", formData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addOrder`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData(initialFormData);
        setSubmitSuccess(true);
      } else {
        const errorText = await response.text();
        console.error('Error al enviar el pedido', errorText);
        alert('Error al enviar el pedido: ' + errorText);
      }
    } catch (error) {
      console.error('Error al enviar el pedido:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <SignOutHeader />
      <Container maxWidth="sm" sx={{ mt: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1A237E' }}>
          Nuestro Servicio
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <TextField
            fullWidth
            label="Nombre"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }}
            inputProps={{ maxLength: 100 }}
          />
          <TextField
            fullWidth
            label="Teléfono"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            fullWidth
            label="Dirección"
            type="text"
            name="direction"
            value={formData.direction}
            onChange={handleChange}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }}
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            fullWidth
            label="Ciudad"
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }}
            inputProps={{ maxLength: 50 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Tipo de entrega</InputLabel>
            <Select
              name="deliveryType"
              value={formData.deliveryType}
              onChange={handleChange}
              label="Tipo de entrega"
              sx={{ backgroundColor: '#F5F5F5' }}
            >
              <MenuItem value="Regular">Regular</MenuItem>
              <MenuItem value="Express">Express</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Mensaje"
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            sx={{ mb: 2, backgroundColor: '#F5F5F5' }}
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#1A237E', color: '#FFFFFF', mt: 2 }}
          >
            Enviar Pedido
          </Button>
          {submitSuccess && <Alert severity="success" sx={{ mt: 2 }}>Su pedido ha sido enviado. ¡Gracias!</Alert>}
        </Box>
      </Container>
    </div>
  );
}

export default OrderForm;
/*

import React, { useState, useEffect } from 'react';
//import RegularHeader from '../components/RegularHeader';
import SignOutHeader from '../components/SignOutHeader';
import { useSelector } from 'react-redux';
import '../OrderForm.css';

function OrderForm() {
  const clientEmail = useSelector(state => state.user.user?.email);

  const initialFormData = {
    clientID: clientEmail || '',
    name: '',
    phoneNumber: '',
    direction: '',
    city: '',
    message: '',
    deliveryType: 'Regular'
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientEmail) {
      setFormData(currentFormData => ({ ...currentFormData, clientID: clientEmail }));
      setLoading(false);
    }
  }, [clientEmail]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (submitSuccess) {
      setSubmitSuccess(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.length < 3) errors.name = 'El nombre debe tener al menos 3 caracteres';
    if (formData.name.length > 100) errors.name = 'El nombre no puede tener más de 100 caracteres';
    if (formData.phoneNumber.length < 7) errors.phoneNumber = 'El número de teléfono debe tener al menos 7 caracteres';
    if (formData.phoneNumber.length > 20) errors.phoneNumber = 'El número de teléfono no puede tener más de 20 caracteres';
    if (formData.direction.length < 10) errors.direction = 'La dirección debe tener al menos 10 caracteres';
    if (formData.direction.length > 200) errors.direction = 'La dirección no puede tener más de 200 caracteres';
    if (formData.city.length < 2) errors.city = 'La ciudad debe tener al menos 2 caracteres';
    if (formData.city.length > 50) errors.city = 'La ciudad no puede tener más de 50 caracteres';
    if (formData.message.length < 5) errors.message = 'El mensaje debe tener al menos 5 caracteres';
    if (formData.message.length > 250) errors.message = 'El mensaje no puede tener más de 250 caracteres';

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors).join('\n'));
      return;
    }

    console.log("Enviando estos datos:", formData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addOrder`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData(initialFormData);
        setSubmitSuccess(true);
      } else {
        const errorText = await response.text();
        console.error('Error al enviar el pedido', errorText);
        alert('Error al enviar el pedido: ' + errorText);
      }
    } catch (error) {
      console.error('Error al enviar el pedido:', error);
    }
  };

  if (loading) {
    return <p>Cargando...</p>;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '20px' }}>
      <SignOutHeader />
      <div className="form-container">
        <h1>Nuestro Servicio</h1>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: '20px 0' }}>
          <label>
            Nombre: <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Nombre" style={{ marginBottom: '10px' }} maxLength={100} />
          </label>
          <label>
            Teléfono: <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Teléfono" style={{ marginBottom: '10px' }} maxLength={20} />
          </label>
          <label>
            Dirección: <input type="text" name="direction" value={formData.direction} onChange={handleChange} placeholder="Dirección" style={{ marginBottom: '10px' }} maxLength={200} />
          </label>
          <label>
            Ciudad: <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Ciudad" style={{ marginBottom: '10px' }} maxLength={50} />
          </label>
          <label>
            Tipo de entrega:
            <select name="deliveryType" value={formData.deliveryType} onChange={handleChange} style={{ marginBottom: '10px' }}>
              <option value="Regular">Regular</option>
              <option value="Express">Express</option>
            </select>
          </label>
          <label>
            Mensaje: <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Mensaje" style={{ marginBottom: '10px' }} maxLength={500}></textarea>
          </label>
          <button type="submit" style={{
            backgroundColor: '#233b6e',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            width: '100%',
            marginTop: '10px',
            borderRadius: '5px',
            transition: 'background-color 0.2s',
            ':hover': {
              backgroundColor: '#1e2a51'
            }
          }}>Enviar Pedido</button>
          {submitSuccess && <div style={{ color: 'green', marginTop: '20px' }}>Su pedido ha sido enviado. ¡Gracias!</div>}
        </form>
      </div>
    </div>
  );
  
}

export default OrderForm;


*/